.idInput {
  width: 100%;
  max-width: 1196px;
  height: 30px;
  margin-bottom: 10px;
  padding: 0;
  text-align: center;
  font-size: 16px;
  border-radius: 4px;
}

.locker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(224, 224, 224, 0.623);
  z-index: 2;
}
