.block {
  width: 100%;
  height: 75px;
  margin: auto;
  margin-bottom: 12px;
  font-size: 18px;
  background-color: rgb(248, 249, 252);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success > span {
  width: 38px;
  height: 38px;
  text-align: center;
  border: 2px solid white;
  border-radius: 50%;
}

.success > span::after {
  content: '✓';
  font-size: 34px;
  margin-left: 3px;
}

.success {
  background: #5cb85c;
  color: white;
  border-color: white;
}

.warning {
  background: #eed202;
  color: black;
  border-color: white;
}

.warning > span {
  width: 34px;
  height: 34px;
  margin-right: 4px;
  text-align: center;
  border: 2px solid white;
  border-radius: 50%;
}

.warning > span::after {
  content: '!';
  font-size: 30px;
  color: white;
}

.alert {
  background: #ed2939;
  color: white;
  border-color: white;
}

.alert > span {
  width: 34px;
  height: 34px;
  margin-right: 4px;
  /* text-align: center; */
  border: 2px solid white;
  border-radius: 50%;
}

.alert > span::after {
  content: 'x';
  font-size: 26px;
  color: white;
  padding-left: 1px;
}
