.statusBlock {
  width: 100%;
  height: 50px;
  margin-top: 3px;
  background-color: rgb(248, 249, 252);
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: black;
  font: 14px -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  font-weight: 700;
  user-select: none;
  position: relative;
}

.redCircle {
  width: 16px;
  height: 16px;
  margin-left: 16px;
  margin-right: 40px;
  border-radius: 50%;
  background-color: red;
}

.statusValue {
  position: absolute;
  right: 26px;
}
