.upperBlocks {
  display: flex;
  flex-direction: column;
}

.blocks {
  width: 100%;
  min-width: 310px;
  height: 75px;
  color: white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font: 18px -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  font-weight: 700;
}

.blocks > span {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 14px;
  color: rgb(255, 255, 255);
  user-select: none;
}

.guideBlock {
  background-color: rgb(0, 100, 224);
}

.printButton {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.subBlocks {
  display: flex;
  margin: 10px 0;
}

.subBlocks > div:nth-child(1) {
  background-color: rgb(0, 224, 218);
  width: 40%;
  margin-right: 10px;
}

.subBlocks > div:nth-child(2) {
  background-color: rgb(148, 46, 131);
  max-width: 60%;
}
