.printersContainer {
  margin-top: 10px;
}

.printersContainer select {
  min-width: 250px;
}

.printersContainer input[type='text'] {
  margin-right: 4px;
  letter-spacing: 1px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.printersContainer > .block > div > select,
.printersContainer > .block > div > input[type='text'] {
  height: 30px;
  padding-left: 10px;
  font-size: 14px;
}

.printersContainer label {
  font-size: 15px;
  margin-bottom: 4px;
}

.switch {
  height: 16px;
  width: 49px;
  border: 2px solid rgba(0, 0, 0, 0.288);
  padding: 4px;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
}

.slider {
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 2px;
  transition: 350ms;
}

.switchRed {
  background-color: #ff7f7f;
}

.switchRed > .slider {
  transform: translateX(0);
}

.switchGreen > .slider {
  transform: translateX(33px);
}

.switchGreen {
  background-color: #90ee90;
}

.switch > input[type='checkbox'] {
  display: none;
}

.block {
  background-color: rgb(248, 249, 252);
  margin-top: 6px;
  padding: 8px 12px;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
}

.inputBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.validateButton {
  height: 34px;
  width: 34px;
  margin-left: 4px;
  background-color: none;
  border: none;
  margin-top: 20px;
  border-radius: 50%;
  cursor: pointer;
}

.greenBorder {
  border: 2px solid green;
}

.redBorder {
  border: 2px solid red;
}

.reload {
  width: 30px;
  height: 30px;
  padding: 0;
  background: none;
  margin-top: 21px;
  margin-left: 8px;
  border: 2px solid black;
  border-radius: 50%;
  cursor: pointer;
}

.reload > img {
  margin: 0;
  width: 18px;
  height: 18px;
}
